.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.accordion {
  width: 100%;
  max-width: 100%;
}
.panel {
  background-color: #FFFFFF;
  box-shadow: 1px 6px 24px 0px rgba(136,136,136,0.75);
}
.panel__label {
  color:#000000;
  position: relative;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 25px 60px 25px 25px;
  font-weight: 500;
  font-size: 14px;
  font-family: Lato Verdana, Geneva, Tahoma, sans-serif;
  font-family: inherit;
  transition: color 0.2s linear;
  cursor: pointer;
  text-align: start;
}
.panel__label:focus {
  outline: none;
}
.panel__label:after,
.panel__label:before {
  content: '';
  position: absolute;
  right: 25px;
  top: 50%;
  width: 12px;
  height: 2px;
  margin-top: -2px;
  background-color: #372717;
}
.panel__label:before {
  transform: rotate(-90deg);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel[aria-expanded='true'] .panel__content {
  opacity: 1;
}
.panel[aria-expanded='true'] .panel__label {
  color: #EF7822;
}
.panel[aria-expanded='true'] .panel__label:before {
  transform: rotate(0deg);
}
.panel__inner {
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel__content {
  margin: 5px 25px 25px;
  font-size: 14px;
  text-align: left;
  color: #000000;
  opacity: 0;
  transition: opacity 0.3s linear 0.18s;
}
.panel:not(:last-child) {
  margin-bottom: 3px;
}

.slide-container button {
  display: none !important;
}

/*
.topframe {
  width: 100%;
  height: 100%;
  animation: slider 5s linear inifite;
  opacity: 1
}

@keyframes slider {
  0% {

  }
  30% {

  }
  50% {

  }
  100% {

  }
}


input[type='checkbox']:checked
{
    position:relative;
}
input[type='checkbox']:checked:before
{
    position: absolute;
    left: 2px;
    right: 2px;
    top: 50%;
    height: 50%;
    width: 3px;
    background-color: #336699;
    content: "";
    transform: translateX(5px) rotate(-45deg);
    transform-origin: left bottom;
}

input[type='checkbox']:checked:after {
  position: absolute;
  left: 2px;
  right: 2px;
  bottom: 0;
  height: 3px;
  width: 80%;
  background-color: #336699;
  content: "";
  transform: translateX(5px) rotate(-45deg);
  transform-origin: left bottom;
}

*/